import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { currency, container, bottomUnderLine } from "./infoCards.module.css";
import footerImage from "../../images/BNW_logo_png.png";

const Card = ({ imgComp, title, price, path ,showTitle}) => {
  return (
    <>
      <div onClick={()=>window.location.href = path} className="bg-white cursor-pointer text-center sm:pb-5">
        {imgComp}
        <div
          className={`mb-4 font-medium leading-7 pt-6 text-center  tracking-widest sm:leading-7 text-xl`}
        >
          {title}
        </div>
        {showTitle ? <p className={`text-sm pb-1`}>Bookings open</p>:<p className={`text-sm pb-1`}>Starting from</p>}

        <h1 className={` ${currency} text-base font-bold`}>{price}</h1>
        <div className={"py-2"}>
          <span className={`${bottomUnderLine} w-1/6 md:w-12`}></span>
        </div>
        <p  className={`text-xs font-bold py-1 `}>READ MORE</p>
      </div>
    </>
  );
};

export default Card;

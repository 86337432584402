import * as React from "react";
import {} from "../components/wavesComp/heroArea.module.css";
import NavHeader from "../components/NavHeader";
import {StaticImage} from "gatsby-plugin-image";
import Intro from "../components/homeComp/intro";
import InfoCard from "../components/homeComp/infoCards";
import Footer from "../components/homeComp/footer";
import SEO from "../components/App/SEO";
import {siteUrl} from "../components/_utils";


const Home = (props) => {
    const logoComp = (
        <StaticImage
            className="w-28 sm:w-36 align-middle"
            placeholder="tracedSVG"
            src={"../images/BNW_logo_dark.png"}
            alt={"Azizi "}
        />
    );

    const titleParams = {
        title: `BnW Dubai  | ${siteUrl}`,
        description: 'FIND YOUR PERFECT HOME WITH DUBAI\'s LEADING REAL ESTATE CONSULTANCY',
        ogTitle: `BnW Dubai | ${siteUrl}`,
        pageLink: siteUrl,
        ogImage: `${siteUrl}static/backgroundMidrif-9465e4cadbd62438f85d4ac4cf71bea1.jpg`,
        ogImageAlt: 'BnW Dubai',
        ogDescription: 'FIND YOUR PERFECT HOME WITH DUBAI\'s LEADING REAL ESTATE CONSULTANCY',
        orUrl: siteUrl,
        secureImageUrl: `${siteUrl}static/backgroundMidrif-9465e4cadbd62438f85d4ac4cf71bea1.jpg`,
        keywords: 'FIND YOUR PERFECT HOME WITH DUBAI\'s LEADING REAL ESTATE CONSULTANCY',
        canonicalRef: siteUrl,

    }
    return (
        <>
            <SEO data={titleParams}/>

            <NavHeader logoComp={logoComp}/>
            <Intro thankyou={props.thanksText}/>
            <InfoCard/>
            <Footer/>
        </>
    );
};

export default Home;

import { introBg, infoText } from './intro.module.css'
import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Intro = (props) => {
  return (
    <>
      <div
        className={` ${introBg} text-center flex-row w-full content-center bg-center p-4`}
      >
        <section className={`text-center m-0 pt-8 sm:w-4/6 md:bg-center`}>

          {props.thankyou ? <div
                            className={`mb-5 m-auto leading-7 pt-6 text-center  tracking-widest text-3xl  sm:text-6xl sm:leading-10`}>
                            Thank You!
                          </div>
                          : <div
             className={`mb-5 m-auto leading-7 pt-6 text-center  tracking-widest text-base  sm:text-3xl sm:leading-10`}>
             FIND YOUR PERFECT HOME WITH DUBAI'S LEADING REAL ESTATE CONSULTANCY
           </div>
          }

          <div
            className={`${infoText} m-auto text-lg italic leading-7 pt-4 text-center tracking-wides  sm:leading-7`}
          >
            The Property is Partnered with Dubai’s Leading Developers
          </div>
        </section>
      </div>
    </>
  )
}

export default Intro

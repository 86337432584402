import * as React from "react";
import footerImage from "../../images/BNW_logo_png.png";


const Footer = () => {
  return (
    <>
       <div className={'bg-primary-gray'}>
            <div className={'container  py-16'}>
                <img className={'m-auto'} src={footerImage}
                     alt=""/>
            </div>

        </div>
    </>
  );
};

export default Footer;

import {container} from './infoCards.module.css'
import * as React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import Card from './card'

const InfoCard = () => {

    const aziziImgComp = (
        <StaticImage
            src={'../../images/aziziHome.png'}
            alt={'Azizi '}
        />
    )

    const wavesImgComp = (
        <StaticImage
            src={'../../images/waves/heroBanner.png'}
            alt={'waves '}
        />
    )

    const habtoorImgComp = (
        <StaticImage
            src={'../../images/habtoorHome.png'}
            alt={'waves '}
        />
    )
    const habtoorImgComp1 = (
        <StaticImage
            src={'../../images/mirdif/mirdifHomeImage.png'}
            alt={'waves'}
        />
    )

    const theCrest = (
        <StaticImage
            src={'../../images/crest/homeCrest.png'}
            alt={'The Crest'}
        />
    )
    const fiveJBR = (
        <StaticImage
            src={'../../images/fiveJBR/fivejbrHomeImage.png'}
            alt={'waves'}
        />
    )
    const sobhaImage = (
        <StaticImage
            src={'../../images/oceano/homeOceano.jpg'}
            alt={'oceano'}
        />
    )
    const damacImage = (
        <StaticImage
            src={'../../images/damacLagoon/damacHome.png'}
            alt={'waves'}
        />
    )
    const newBeachImage = (
        <StaticImage
            src='../../images/newbeach/newbeachfrontimg.jpg'
            alt='central park'/>
    )
    const centralParkImage = (
        <StaticImage
            src='../../images/centralPark/hero-main.jpg'
            alt='central park'/>
    )
    return (
        <>
            <div className={` ${container} flex-col content-center sm:flex-row px-4 sm:pl-24 sm:pr-24`}>
                <div className={`grid grid-cols-1  sm:grid-cols-4 md:grid-cols-4 gap-4`}>
                {/* /al-marjan-island/oceano */}
                    <Card imgComp={sobhaImage} title={'OCEANO'} path={'/al-marjan-island/oceano'} price={'AED 1.9M'}/>
                    <Card imgComp={theCrest} price={'AED 1.1M'} title={'CREST'} path={'/crest'}/>
                    <Card imgComp={aziziImgComp} title={'AZIZI RIVIERA'} price={'AED 620K'} path={'/azizi'}/>
                    {/*<Card imgComp={wavesImgComp} title={'WAVES'} price={'AED 975,000'} path={'/waves'}/>*/}
                    <Card imgComp={fiveJBR} title={'FIVE JBR'} price={'AED 2.7M'} path={'/fiveJBR'}/>
                </div>
            </div>
            <div className={`${container} flex-col content-center sm:flex-row p-4 sm:pl-24 sm:pr-24 pb-8`}>
                <div className={`grid grid-cols-1  sm:grid-cols-4 md:grid-cols-4 gap-4`}>
                    <Card imgComp={habtoorImgComp1} title={'MIRDIF HILLS'} price={'AED 550k'} path={'/mirdifHills'}/>
                    <Card imgComp={habtoorImgComp} title={'AI HABTOOR CITY'} price={'AED 1.1M'} path={'/'}/>
                    <Card imgComp={damacImage} title={'DAMAC LAGOONS'} price={'AED 1.7M'} path={'/damac-lagoons'}/>
                    <Card imgComp={newBeachImage} title={'EMAAR BEACH FRONT'} path={'/emaar-beach-front'}/>
                    <Card imgComp={centralParkImage} title={'CENTRAL PARK'} path={'/central-park'}/>
                    <Card imgComp={centralParkImage} title={'Bricks n Woods Real Estate'}
                          path={'/topPropertiesBelow250k'}/>

                </div>
            </div>


        </>
    )
}

export default InfoCard
